import { CSSProperties, forwardRef } from 'react';

type Props = {
  inline?: boolean;
  gap?: CSSProperties['gap'];
  align?: CSSProperties['alignItems'];
  width?: CSSProperties['width'];
  minWidth?: CSSProperties['minWidth'];
  height?: CSSProperties['height'];
  children: React.ReactNode;
};

export const Stack = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        display: props.inline ? 'inline-flex' : 'flex',
        gap: props.gap,
        alignItems: props.align || 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: props.width || '100%',
        minWidth: props.minWidth,
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
});
