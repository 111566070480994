import { TooltipPortal } from './components/TooltipPortal';
import styles from './styles.module.scss';
import { Props } from './types';

import { useRef, useState } from 'react';

export const Tooltip: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const getHandlerToShow =
    <T,>(handler?: (e: T) => void) =>
    (e: T) => {
      handler && handler(e);
      if (!ref.current) {
        return;
      }

      setRect(ref.current.getBoundingClientRect());
      setIsVisible(true);
    };

  const getHandlerToHide =
    <T,>(handler?: (e: T) => void) =>
    (e: T) => {
      handler && handler(e);
      setIsVisible(false);
    };

  return (
    <div
      className={styles.wrapper}
      ref={ref}
      onPointerEnter={getHandlerToShow(props.onPointerEnter)}
      onTouchStart={getHandlerToShow(props.onTouchStart)}
      onFocus={getHandlerToShow(props.onFocus)}
      onPointerLeave={getHandlerToHide(props.onPointerLeave)}
      onTouchEnd={getHandlerToHide(props.onTouchEnd)}
      onBlur={getHandlerToHide(props.onBlur)}
    >
      {props.trigger}
      <TooltipPortal
        isVisible={!props.isDisabled && isVisible}
        parentRect={rect}
        direction={props.direction}
        triggerType={props.triggerType ?? 'icon'}
        size={props.size}
      >
        {props.children}
      </TooltipPortal>
    </div>
  );
};
